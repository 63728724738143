'use strict';

angular.module('informaApp')
    .directive('infCheckboxSlider', ["$timeout", function ($timeout) {
        return {
            restrict: 'E',
            templateUrl: 'directives/inf-checkbox-slider/template.ptl.html',
            scope: {
                label: "@",
                name: "@",
                model: "="
            },
            link: function (scope, element, attr) {
                scope.valueOff = false;
                scope.valueOn = true;
                scope.valueNull = null;
            }
        }
    }]);
